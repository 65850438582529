import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

const FUND_QUERY = gql(`
  query GetFundInvestments($id: ID!) {
    fund(id: $id) {
      id
      investments {
        nodes {
          id
          createdAt
          amount
          currentValue
          realizedValue
          status
          deal {
            companyInformation {
              company {
                name
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
      statistics {
        totalUnrealizedValue
        totalInvestedAmount
        numberOfDeals
        multipleOnInvestment
      }
    }
  }
`);

const FundInvestments: FC = () => {
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };

  const { loading, error, data, refetch, fetchMore } = useQuery(FUND_QUERY, {
    variables: {
      id: fundId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const fund = data.fund;
  const investments = fund.investments.nodes;
  const pageInfo = fund.investments.pageInfo;

  return (
    <>
      <Statistics
        statistics={[
          {
            label: 'Total Amount Invested',
            value: fund.statistics.totalInvestedAmount,
            type: 'CURRENCY',
          },
          {
            label: 'Total Unrealized Value',
            value: fund.statistics.totalUnrealizedValue,
            type: 'CURRENCY',
          },
          {
            label: 'Multiple on Investment',
            value: fund.statistics.multipleOnInvestment,
            type: 'MULTIPLE',
          },
          {
            label: 'Number of Holdings',
            value: fund.statistics.numberOfDeals,
            type: 'NUMBER',
          },
        ]}
      />
      <DataTable
        data={investments}
        searchFields={['deal.companyInformation.company.name']}
        columns={[
          {
            label: 'Deal',
            fieldName: 'deal.companyInformation.company.name',
          },
          {
            label: 'Created At',
            fieldName: 'createdAt',
            type: 'DATE',
          },
          {
            label: 'Amount',
            fieldName: 'amount',
            type: 'CURRENCY',
          },
          {
            label: 'Current Value',
            fieldName: 'currentValue',
            type: 'CURRENCY',
          },
          {
            label: 'Realized Value',
            fieldName: 'realizedValue',
            type: 'CURRENCY',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default FundInvestments;
