import { useQuery } from '@apollo/client';
import { BuildingOffice2Icon, EyeIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import DeletePostButton from './DeletePostButton';
import PublishPostButton from './PublishPostButton';
import UpdatePost from './UpdatePost';
import UpdatePostStatus from './UpdatePostStatus';

const POST_QUERY = gql(`
  query getPost($id: ID!) {
    post(id: $id) {
      id
      title
      message
      status
      createdAt
      updatedAt
      permissions {
        canUpdatePostStatus
      }
      deal {
        id
      }
      syndicate {
        id
      }
    }
  }
`);

const PostPage: FC = () => {
  const navigate = useNavigate();
  const { postId } = useParams<{ postId: string }>() as { postId: string };

  const { loading, error, data, refetch } = useQuery(POST_QUERY, {
    variables: {
      id: postId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const post = data.post;

    return (
      <>
        <SidebarLayoutNavigation
          title="Post"
          subTitle="Manage Post"
          onBackButtonClick={() => navigate(-1)}
          actionButtons={[
            <NavLink
              type="button"
              className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              title="Go to Deal Page"
              to={`/deals/${post.deal?.id}`}
              target="_blank"
            >
              <span className="sr-only">Deal</span>
              <BuildingOffice2Icon className="h-5 w-5" aria-hidden="true" />
            </NavLink>,
            <NavLink
              type="button"
              className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              title="Go to Syndicate Page"
              target="_blank"
              to={`/syndicates/${post.syndicate.id}`}
            >
              <span className="sr-only">Syndicate</span>
              <EyeIcon className="h-5 w-5" aria-hidden="true" />
            </NavLink>,
          ]}
        />
        <SidebarLayoutContent>
          <UpdatePost id={post.id} title={post.title} message={post.message} />
          <UpdatePostStatus
            id={post.id}
            status={post.status}
            canUpdatePostStatus={post.permissions.canUpdatePostStatus}
          />
          <div className="w-full grid grid-flow-col space-x-4 mt-8">
            <DeletePostButton id={post.id} />
            <PublishPostButton id={post.id} canPublishPost={post.permissions.canUpdatePostStatus} />
          </div>
        </SidebarLayoutContent>
      </>
    );
  };

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default PostPage;
