import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const PUBLISH_POST_MUTATION = gql(`
  mutation PublishPost($id: ID!) {
    publishPost(id: $id) {
      id
      status
    }
  }
`);

const PublishPostButton: FC<{ id: string; canPublishPost: boolean }> = ({ id, canPublishPost }) => {
  const [removePost, { loading, error }] = useMutation(PUBLISH_POST_MUTATION);

  return (
    <ConfirmButton
      onConfirm={() => removePost({ variables: { id } })}
      loading={loading}
      error={error}
      disabled={!canPublishPost}
      description="Publishing a post will trigger an email to all post recipients. This cannot be undone."
    >
      Publish
    </ConfirmButton>
  );
};

export default PublishPostButton;
